import { Collapse, Spinner, Table } from 'reactstrap';
import { successMsg } from '../../../../cache/vars';
import moment from 'moment';
import { useMutation } from '@apollo/client';
import { GET_TICKETS_VIA_1TICKETS, TRANSFER_TICKET } from '../../../../queries/vip-workflow';
import {
  GET_PACKAGE_PO_INFO_FOR_TICKETS_DASHBOARD,
  GET_TICKETS_LIST_FOR_TICKETS_MODAL,
} from '../../../../queries/ticket';
import './ticketsTransfer.scss';
import React from 'react';
import { TRANSFER_STATUSES } from '../../../../utils/constants';

const ICONS_BY_STATYSES = {
  TRANSFERED_EXTERNALLY: (
    <i className="fa fa-sharp fa-lg fa-regular fa-ban" style={{ color: '#0F9E48' }} />
  ),
  NOT_TRANSFERABLE: (
    <i className="fa fa-light fa-lg fa-triangle-exclamation" style={{ color: '#CE4275' }} />
  ),
  TRANSFERABLE: <i className="fa fa-light fa-lg fa-circle-check" style={{ color: '#E19B12' }} />,
  EMAILED: <i className="fa fa-light fa-lg fa-circle-check" style={{ color: 'green' }} />,
  FAILED: <i className="fa fa-light fa-lg fa-circle-xmark" style={{ color: '#CE4275' }} />,
  FAILED_MAPPING: <i className="fa fa-light fa-lg fa-circle-xmark" style={{ color: '#CE4275' }} />,
  UPLOADED: <i className="fa fa-lg fa-light fa-calendar" style={{ color: 'grey' }} />,
  PENDING: <i className="fa fa-lg fa-duotone fa-spinner-third" style={{ color: '#E19B12' }} />,
};

const TicketsTransfer = ({ invoiceId, ticketsData, packagePoList, isInvoiceArchied, user }: any) => {
  const [isPoDetailsOpen, setIsPoDetailsOpen] = React.useState(false);

  const [transferTicket, transferTicketInfo] = useMutation(TRANSFER_TICKET, {
    variables: { invoiceId },
    refetchQueries: [GET_TICKETS_LIST_FOR_TICKETS_MODAL, 'getTicketsListForTicketsModal'],
    onCompleted(data) {
      successMsg(data.transferTickets.status);
    },
  });

  const [checkTransferability, checkTransferabilityInfo] = useMutation(GET_TICKETS_VIA_1TICKETS, {
    variables: { invoiceId, user },
    refetchQueries: [GET_TICKETS_LIST_FOR_TICKETS_MODAL, GET_PACKAGE_PO_INFO_FOR_TICKETS_DASHBOARD],
    onCompleted() {
      successMsg(checkTransferabilityInfo.data.getTicketInfoFromTicketsApi.status);
    },
  });

  const eventDetailsArray =
    ticketsData.data?.getTicketsListForTicketsModal &&
    JSON.parse(ticketsData.data?.getTicketsListForTicketsModal)['Event Details'];

  return (
    <div style={{ border: '1px solid #E6EEF2', borderRadius: '10px' }} className="mt-3">
      <div
        className="pt-3 pb-3 pl-4 pr-4 d-flex align-items-baseline justify-content-between"
        style={{ borderBottom: '1px solid #E6EEF2' }}
      >
        <h2 className="m-0">Tickets Transfer</h2>
        {!isInvoiceArchied &&
        !eventDetailsArray?.some((el: any) => {
          return !TRANSFER_STATUSES.includes(el.deliveryStatus) ? false : true;
        }) ? (
          <button
            style={{ background: 'none', border: 'none' }}
            onClick={(e) => {
              e.preventDefault();

              checkTransferability();
            }}
            className={checkTransferabilityInfo.loading ? 'spin' : ''}
          >
            <i className="fa fa-lg fa-solid fa-arrows-rotate" />
          </button>
        ) : null}
        {eventDetailsArray?.findIndex(
          (el: any) =>
            el.deliveryStatus === 'TRANSFERABLE' ||
            el.deliveryStatus === 'FAILED' ||
            el.deliveryStatus === 'FAILED_MAPPING',
        ) >= 0 ? (
          transferTicketInfo.loading ? (
            <Spinner animation="border" size="sm" />
          ) : (
            <button
              style={{ background: 'none', border: 'none' }}
              onClick={(e) => {
                e.preventDefault();

                transferTicket();
              }}
            >
              <i className="fa fa-lg fa-regular fa-paper-plane-top" />
            </button>
          )
        ) : null}
      </div>
      <div className="pt-3 pb-2 pl-4 pr-4">
        {eventDetailsArray?.some((el: any) => el.deliveryStatus !== 'UPLOADED') ? (
          eventDetailsArray.map((ticket: any, idx: number) => {
            if (ticket.deliveryStatus === 'UPLOADED') {
              return;
            }
            const deliveryStatus =
              ticket.deliveryStatus === 'FAILED_MAPPING' ? 'FAILED' : ticket.deliveryStatus;
            return (
              <React.Fragment key={idx}>
                <div className="d-flex flex-column">
                  <span style={{ color: '#6B7395' }}>Status</span>
                  <span>
                    {deliveryStatus}
                    &nbsp;&nbsp;&nbsp;
                    {ICONS_BY_STATYSES[deliveryStatus as keyof typeof ICONS_BY_STATYSES]}
                  </span>
                </div>
                <div className="d-flex flex-column mt-2">
                  <span style={{ color: '#6B7395' }}>Last Check</span>
                  <span>
                    {ticket.updatedAt ? moment(ticket.updatedAt).format('LT MM/DD/YYYY') : '-'}
                  </span>
                </div>
                <div className="d-flex flex-column mt-2">
                  <span style={{ color: '#6B7395' }}>Transfer ID</span>
                  <span>{ticket.ticketsTransferId ? ticket.ticketsTransferId : '-'}</span>
                </div>
                <div className="d-flex flex-column mt-2">
                  <span style={{ color: '#6B7395' }}>Acceptance URL</span>
                  {ticket.eTicketLink && ticket.deliveryStatus !== 'UPLOADED' ? (
                    <span
                      style={{
                        fontStyle: 'italic',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                      }}
                    >
                      {ticket.eTicketLink}
                      &nbsp;&nbsp;&nbsp;
                      <button
                        style={{ background: 'none', border: 'none' }}
                        onClick={(e) => {
                          e.preventDefault();

                          navigator.clipboard.writeText(ticket.eTicketLink);
                          successMsg('Copied to clickboard');
                        }}
                      >
                        <i className="fa fa-lg fa-regular fa-copy"></i>
                      </button>
                    </span>
                  ) : ticket.deliveryStatus === 'FAILED' ||
                    ticket.deliveryStatus === 'FAILED_MAPPING' ? (
                    <span style={{ fontStyle: 'italic' }}>{ticket.errorMessage}</span>
                  ) : ticket.deliveryStatus === 'NOT_TRANSFERABLE' ? (
                    <span style={{ fontStyle: 'italic' }}>{ticket.errorLogs || ''}</span>
                  ) : (
                    <span style={{ fontStyle: 'italic' }}>
                      Will transfer on 14 days before event
                    </span>
                  )}
                </div>
              </React.Fragment>
            );
          })
        ) : (
          <>
            <div className="d-flex flex-column">
              <span>Status</span>
              <span>-</span>
            </div>
            <div className="d-flex flex-column mt-2">
              <span>Last Check</span>
              <span>-</span>
            </div>
            <div className="d-flex flex-column mt-2">
              <span>Transfer ID</span>
              <span>-</span>
            </div>
            <div className="d-flex flex-column mt-2">
              <span>Acceptance URL</span>
              <span>-</span>
            </div>
          </>
        )}
      </div>
      <div className="po-opener">
        <button
          onClick={() => {
            setIsPoDetailsOpen((prev) => {
              return !prev;
            });
          }}
        >
          {isPoDetailsOpen ? (
            <i className="fa fa-lg fa-solid fa-chevron-up"></i>
          ) : (
            <i className="fa fa-lg fa-solid fa-chevron-down"></i>
          )}
        </button>
      </div>
      <Collapse isOpen={isPoDetailsOpen}>
        <div className="pt-3 pb-2 pl-4 pr-4">
          {packagePoList &&
            Object.entries(JSON.parse(packagePoList)).map(
              ([detailsKey, detailsValue]: any, id: number) => {
                return detailsValue.map((item: any, id: number) => {
                  return (
                    <div key={id} style={{ borderTop: '1px solid #E6EEF2' }} className="mb-4">
                      <div className="d-flex mt-2 justify-content-between">
                        <span>PO ID</span>
                        <span>{item.packagePoId}</span>
                      </div>
                      <div className="d-flex mt-2 justify-content-between">
                        <span>Section</span>
                        <span>{item.section || '-'}</span>
                      </div>
                      <div className="d-flex mt-2 justify-content-between">
                        <span>Row</span>
                        <span>{item.row || '-'}</span>
                      </div>
                      <div className="d-flex mt-2 justify-content-between">
                        <span>Seat</span>
                        <span>{item.seat || '-'}</span>
                      </div>
                      <div className="d-flex mt-2 justify-content-between">
                        <span>Face</span>
                        <span>{item.face || '-'}</span>
                      </div>
                      <div className="d-flex mt-2 justify-content-between">
                        <span>Cost</span>
                        <span>{item.cost || '-'}</span>
                      </div>
                      <div className="d-flex mt-2 justify-content-between">
                        <span>Errors</span>
                        <span>{item.error || '-'}</span>
                      </div>
                    </div>
                  );
                });
              },
            )}
        </div>
      </Collapse>
    </div>
  );
};

export default TicketsTransfer;
