import { useApolloClient } from '@apollo/client';
import moment from 'moment';
import React from 'react';
import { Col, Modal } from 'reactstrap';
import { GET_CART_DETAILS } from '../../../queries/vip-workflow';
import {
  FULLY_EDITABLE_CARDS,
  PARKING_OPTION_DESCRIPTION,
  transformCardDesciptions,
  transformTailgateOptionDescription,
  transformTicketMessageAfterSent,
  transformTicketMessageBeforeSent,
} from '../../../utils/constants';
import { IEditPackageDetailsModal } from '../../../utils/interfaces';
import CloseModalButton from '../../Buttons/CloseModalButton';
import CardTitle from './CardTitle';
import EditCardForm from './EditCardForm';
import EditFullyEditableCardForm from './EditFullyEditableCardForm';
import './editPackageDetailsModal.scss';

const EditPackageDetailsModal = ({
  isModalOpened,
  setIsModalOpened,
  modalGroup,
  setModalGroup,
  packageDetailsId,
  setPackageDetailsId,
  setPackageOrderWithDetails,
  modaName,
  setModalName,
  packageOrderWithDetails,
  invoiceId,
  activeCardsFilter,
  defaultAirportTransferType,
  defaultVegasLimoTransferType, 
  ticketTypes,
  cardDescriptions,
}: IEditPackageDetailsModal) => {
  const client = useApolloClient();
  const [modalState, setModalState] = React.useState<any>({});
  const [airportTransferType, setAirportTransferType] = React.useState<'transfer1' | 'transfer2'>(
    'transfer1',
  );
  const [vegasLimoTransferType, setVegasLimoTransferType] = React.useState<'transfer1' | 'transfer2'>(
    'transfer1',
  );
  const [isEditCardTitle, setIsEditCardTitle] = React.useState<boolean>(false);
  const [cardTitle, setCardTitle] = React.useState<string>('');
  const editCardTitleRef = React.useRef(null);  
  
  const getCartDetailsQuery = async () => {
    const { data } = await client.query({
      query: GET_CART_DETAILS,
      variables: { packageDetailsId },
      fetchPolicy: 'network-only',
    });
    setCardTitle(
      data?.getCartDetailsByPackageDetailsId.cardTitle
        ? data?.getCartDetailsByPackageDetailsId.cardTitle
        : modaName,
    );

    const cartData = data?.getCartDetailsByPackageDetailsId.cartJsonData
      ? JSON.parse(data?.getCartDetailsByPackageDetailsId.cartJsonData)
      : FULLY_EDITABLE_CARDS.includes(modalGroup)
      ? []
      : {};

    if (cartData?.ticketsSentDate) {
      const dataUTC = moment(cartData?.ticketsSentDate, 'YYYY-MM-DD').utc(false).toDate();
      cartData.ticketsSentDate = moment(dataUTC).format('YYYY-MM-DD');
    }

    setModalState({
      cartId: data?.getCartDetailsByPackageDetailsId.cartId,
      packageDetailsId: data?.getCartDetailsByPackageDetailsId.packageDetailsId,
      packageOrderId: data?.getCartDetailsByPackageDetailsId.packageOrderId,
      cardTitle: data?.getCartDetailsByPackageDetailsId.cardTitle,
      cartData,
      typeId: data?.getCartDetailsByPackageDetailsId.typeId,
    });
  };

  React.useEffect(() => {
    if (isModalOpened && packageDetailsId) {
      getCartDetailsQuery();
      setAirportTransferType(defaultAirportTransferType);
      setVegasLimoTransferType(defaultVegasLimoTransferType);
    }
  }, [isModalOpened]);

  React.useEffect(() => {
    const handleClickOutside = (e: any) => {
      if (
        editCardTitleRef.current &&
        //@ts-expect-error
        !editCardTitleRef?.current?.contains(e.target) &&
        e.target.nodeName !== 'BUTTON'
      ) {
        setCardTitle(modalState.cardTitle ? modalState.cardTitle : modaName);
        setIsEditCardTitle(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [editCardTitleRef, isModalOpened, modalState]);

  const handleInputs = (e: any) => {
    const isCheckbox = e.target.type === 'checkbox';
    if (isCheckbox) {
      setModalState((state: any) => {
        if (modaName === 'Event Details') {
          return {
            ...state,
            cartData: {
              ...state.cartData,
              [e.target.name]: e.target.checked,
              ticketsSentDate: e.target.checked ? moment().format('YYYY-MM-DD') : '',
              notes: e.target.checked
              ? transformTicketMessageAfterSent(
                ticketTypes,
                state.cartData.ticketType,
                packageOrderWithDetails,
                modalState.cartData.link,
                new Date())
              : transformTicketMessageBeforeSent(
                ticketTypes,
                state.cartData.ticketType,
                packageOrderWithDetails,
                modalState.cartData.link
              )  ,
            },
          };
        } else {
          return {
            ...state,
            cartData: {
              ...state.cartData,
              [e.target.name]: e.target.checked,
              ticketsSentDate: e.target.checked ? moment().format('YYYY-MM-DD') : '',
            },
          };
        }
      });
    } else {
      if (modaName === 'Event Details' && e.target.name === 'ticketType') {
        setModalState((state: any) => {
          return {
            ...state,
            cartData: {
              ...state.cartData,
              [e.target.name]: Number(e.target.value),
              ticketsSent: !e.target.value ? false : state?.cartData?.ticketsSent,
              ticketsSentDate: !e.target.value ? '' : state.cartData.ticketsSentDate,
              link:
                !e.target.value || e.target.value !== 13
                  ? ''
                  : state.cartData.link,
              ticketType: Number(e.target.value) || null,
              notes: Number(e.target.value)
                ? (state?.cartData?.ticketsSent
                ? transformTicketMessageAfterSent(
                  ticketTypes,
                  Number(e.target.value),
                  packageOrderWithDetails,
                  modalState.cartData.link,
                  modalState.cartData.ticketsSentDate
                  ? modalState.cartData.ticketsSentDate
                  : new Date()
                  )
                : transformTicketMessageBeforeSent(
                  ticketTypes,
                  Number(e.target.value),
                  packageOrderWithDetails,
                  modalState.cartData.link
                )) 
                : '',
            },
          };
        });
      } else if (modaName === 'Event Details' && e.target.name === 'link') {
        setModalState((state: any) => {
          return {
            ...state,
            cartData: {
              ...state.cartData,
              [e.target.name]: e.target.value,
              notes: state.cartData.ticketsSent
              ? transformTicketMessageAfterSent(
                ticketTypes,
                state.cartData.ticketType,
                packageOrderWithDetails,
                e.target.value,
                new Date())
              : transformTicketMessageBeforeSent(
                ticketTypes,
                state.cartData.ticketType,
                packageOrderWithDetails,
                e.target.value
              )
            },
          };
        });
      } else if (modaName === 'Event Details' && e.target.name === 'ticketsSentDate') {
        setModalState((state: any) => {
          return {
            ...state,
            cartData: {
              ...state.cartData,
              [e.target.name]: e.target.value,
              notes: state.cartData.ticketsSent
              ? transformTicketMessageAfterSent(
                ticketTypes,
                state.cartData.ticketType,
                packageOrderWithDetails,
                modalState.cartData.link,
                e.target.value)
              : transformTicketMessageBeforeSent(
                ticketTypes,
                state.cartData.ticketType,
                packageOrderWithDetails,
                modalState.cartData.link
              )
            },
          };
        });
      } else if (modaName === 'Merchandise' && e.target.name === 'merchDesc') {
        setModalState((state: any) => {
          const groupId = packageOrderWithDetails.orderDetails.find(
            (order: any) => order.packageDetailsId === packageDetailsId,
          ).groupId;

          return {
            ...state,
              cartData: {
              ...state.cartData,
              [e.target.name]: e.target.value,
              description: transformCardDesciptions(groupId, e.target.value, cardDescriptions),
            },
          };
        });
      } else if (modaName === 'City Pass Details' && e.target.name === 'link') {
        setModalState((state: any) => {
          let currentDescription = state.cartData.description || '';
          
          const trimmedValue = e.target.value.trim();
          const linkPart = trimmedValue
            ? ` <a href="${trimmedValue.includes('http://') || trimmedValue.includes('https://')
              ? trimmedValue
              : '//' + trimmedValue
            }" target="_blank">HERE</a>`
            : '';
      
          const updatedDescription = currentDescription.replace(/ <a href=".*?" target="_blank">HERE<\/a>/, '') + linkPart;

          return {
            ...state,
            cartData: {
              ...state.cartData,
              [e.target.name]: e.target.value,
              description: updatedDescription,
            },
          };
        });
      } else if (modaName === 'Rideshare Pass' && e.target.name === 'link') {
        setModalState((state: any) => {
            let currentDescription = state.cartData.description || '';
        
            const trimmedValue = e.target.value.trim();
            const linkPart = trimmedValue
              ? ` <a href="${trimmedValue.includes('http://') || trimmedValue.includes('https://')
                ? trimmedValue
                : '//' + trimmedValue
              }" target="_blank">Lyft app</a>`
              : '';
        
            const updatedDescription = currentDescription.replace(/ <a href=".*?" target="_blank">HERE<\/a>/, '') + linkPart;
        
            return {
              ...state,
              cartData: {
                ...state.cartData,
                [e.target.name]: trimmedValue,
                description: updatedDescription,
              },
            };
        });
      } else if (modaName === 'Parking' && e.target.name === 'parkingOption') {
        setModalState((state: any) => {
          return {
            ...state,
            cartData: {
              ...state.cartData,
              [e.target.name]: e.target.value,
              description:
                PARKING_OPTION_DESCRIPTION[
                  e.target.value as keyof typeof PARKING_OPTION_DESCRIPTION
                ] || '',
            },
          };
        });
      } else if (modaName === 'Tailgate' && e.target.name === 'ticketType') {
        setModalState((state: any) => {
          return {
            ...state,
            cartData: {
              ...state.cartData,
              [e.target.name]: Number(e.target.value),
              ticketType: Number(e.target.value) || null,
              description: transformTailgateOptionDescription(Number(e.target.value), ticketTypes) || '',
            },
          };
        });
      } else if (modaName === 'Pre-show Hospitality' && e.target.name === 'qty') {
        setModalState((state: any) => {
          return {
            ...state,
            cartData: {
              ...state.cartData,
              [e.target.name]: e.target.value.toString(),
            },
          };
        });
      }
      else if (modaName === 'Pre-show Hospitality' && e.target.name === 'date') {
        setModalState((state: any) => {
          return {
            ...state,
            cartData: {
              ...state.cartData,
              [e.target.name]: e.target.value.toString(),
            },
          };
        });
      }
      else {
        setModalState((state: any) => {
          return {
            ...state,
            cartData: {
              ...state.cartData,
              [e.target.name]: e.target.value.toString(),
            },
          };
        });
      }
    }
  };

  const handleAirportTransferInputs = (e: any) => {
    setModalState((state: any) => {
      return {
        ...state,
        cartData: {
          ...state.cartData,
          [airportTransferType]: {
            ...(state.cartData[airportTransferType as keyof typeof state.cartData] as any),
            [e.target.name]: e.target.value,
          },
        },
      };
    });
  };
  
  const handleVegasLimoTransferInputs = (e: any) => {
    setModalState((state: any) => {
      return {
        ...state,
        cartData: {
          ...state.cartData,
          [vegasLimoTransferType]: {
            ...(state.cartData[vegasLimoTransferType as keyof typeof state.cartData] as any),
            [e.target.name]: e.target.value,
          },
        },
      };
    });
  };

  return (
    <Modal
      isOpen={isModalOpened}
      toggle={() => {
        setPackageDetailsId(null);
        setModalGroup('');
        setModalName('');
        setIsModalOpened(false);
        setModalState({});
        setIsEditCardTitle(false);
      }}
      className="modal-dialog-centered modal-secondary"
      size="xl"
      fade={false}
    >
      <div className="modal-body pb-0">
        <div className="d-flex justify-content-between ">
          <CardTitle
            isEditCardTitle={isEditCardTitle}
            cardTitle={cardTitle}
            setCardTitle={setCardTitle}
            modalState={modalState}
            setPackageOrderWithDetails={setPackageOrderWithDetails}
            packageDetailsId={packageDetailsId}
            setIsEditCardTitle={setIsEditCardTitle}
            modaName={modaName}
            setModalState={setModalState}
            editCardTitleRef={editCardTitleRef}
          />
          <Col sm="1" className="text-right">
            <CloseModalButton
              handleCloseModalButtonClick={(e) => {
                e.preventDefault();
                setPackageDetailsId(null);
                setModalGroup('');
                setModalName('');
                setIsModalOpened(false);
                setAirportTransferType('transfer1');
                setVegasLimoTransferType('transfer1');
                setModalState({});
                setIsEditCardTitle(false);
              }}
            />
          </Col>
        </div>
        {FULLY_EDITABLE_CARDS.includes(modalGroup) ? (
          <EditFullyEditableCardForm
            modalState={modalState}
            setModalState={setModalState}
            setPackageOrderWithDetails={setPackageOrderWithDetails}
            setPackageDetailsId={setPackageDetailsId}
            setModalGroup={setModalGroup}
            setModalName={setModalName}
            setIsModalOpened={setIsModalOpened}
            invoiceId={invoiceId}
            activeCardsFilter={activeCardsFilter}
          />
        ) : (
          <EditCardForm
            modalGroup={modalGroup}
            handleAirportTransferInputs={handleAirportTransferInputs}
            handleVegasLimoTransferInputs={handleVegasLimoTransferInputs}
            handleInputs={handleInputs}
            modalState={modalState}
            setAirportTransferType={setAirportTransferType}
            airportTransferType={airportTransferType}
            setVegasLimoTransferType={setVegasLimoTransferType}
            vegasLimoTransferType={vegasLimoTransferType}
            setModalState={setModalState}
            setPackageDetailsId={setPackageDetailsId}
            setModalGroup={setModalGroup}
            setModalName={setModalName}
            setIsModalOpened={setIsModalOpened}
            setPackageOrderWithDetails={setPackageOrderWithDetails}
            invoiceId={invoiceId}
            activeCardsFilter={activeCardsFilter}
            packageOrderWithDetails={packageOrderWithDetails}
          />
        )}
      </div>
    </Modal>
  );
};

export default EditPackageDetailsModal;
